import {
  ActionPerformed,
  PushNotifications
} from '@capacitor/push-notifications';
import { isPlatform } from './isPlatform';

export function pushActionPerformed(
  callback = (action: ActionPerformed) => {}
) {
  if (isPlatform('capacitor')) {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action: ActionPerformed) => callback(action)
    );
  }
}
