import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { getState, dispatch } from '@flew/state';
import { navRoot } from '../actions/navRoot';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild {
  constructor() {}

  canActivate() {
    return getState('session.user')
      ? true
      : dispatch(navRoot('/auth')) && false;
  }

  canActivateChild() {
    return this.canActivate();
  }
}
