import { createReducer } from '@flew/state';

export const nav = createReducer<{
  lastPathname: string;
  currentPathname: string;
}>(
  {
    lastPathname: null,
    currentPathname: null
  },
  {
    navForward: (state, action) => {
      state.lastPathname = state.currentPathname;
      state.currentPathname = action.payload;
    },
    navBack: (state, action) => {
      state.lastPathname = state.currentPathname;
      state.currentPathname = action.payload;
    },
    navRoot: (state, action) => {
      state.lastPathname = state.currentPathname;
      state.currentPathname = action.payload;
    }
  }
);
