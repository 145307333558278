import { createReducer } from '@flew/state';
import { User } from '../interfaces/user.interface';

export const session = createReducer<{
  user: User;
}>(
  {
    user: null
  },
  {
    sessionSet: (state, action) => {
      // tslint:disable-next-line: forin
      for (const k in action.payload) {
        state[k] = action.payload[k];
      }
    },
    sessionSetUser: (state, action) => (state.user = action.payload)
  }
);
