import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from './404-page.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [NotFoundPageComponent],
  imports: [CommonModule, IonicModule],
  exports: [NotFoundPageComponent]
})
export class NotFoundPageModule {}
