import 'hammerjs';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import * as reducers from './reducers';

import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { FIREBASE_CONFIG } from './config/firebase';

// flew setup
// more at https://flew.dev
import { setup } from '@flew/core';
import { cachePlugin } from '@flew/cache';
import { firebasePlugin } from '@flew/firebase';
import { statePlugin } from '@flew/state';

setup({
  options: {
    silent: environment.production || true,
    driver: 'firestore',
    timestampCreated: 'createdAt',
    timestampUpdated: 'updatedAt',
    identifier: 'objectId',
    httpConfig: {
      timeout: 60 * 1000 * 2
    }
  },
  plugins: [
    firebasePlugin({
      config: FIREBASE_CONFIG
    }),
    cachePlugin({
      name: environment.dbName,
      store: environment.dbStore
    }),
    statePlugin({
      production: environment.production,
      trace: true,
      traceLimit: 25,
      reducers
    })
  ]
});

// client stuff
import { AppStart } from './effects/appStart';

// hammer setup
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // only use the swap gesture so
    // deactivate the others to avoid overlaps
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    IonicModule.forRoot()
  ],
  providers: [
    // Start service
    AppStart,
    {
      provide: APP_INITIALIZER,
      useFactory: (start: AppStart) => () => start.up(),
      deps: [AppStart],
      multi: true
    },
    // ionic route strategy
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // apply hammer config
    {
      provide: HammerGestureConfig,
      useClass: HammerConfig
    }
  ]
})
export class AppModule {}
