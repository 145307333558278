import { publish } from '@flew/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { NavOptions } from '../interfaces/navOptions.interface';

export function navRoot(
  route,
  options: NavigationOptions = {
    animated: true,
    animationDirection: 'forward'
  },
  postNavigation: () => void = () => {}
) {
  return async function (dispatch) {
    publish<NavOptions>('navRoot', {
      route,
      callback: postNavigation,
      extras: options
    });

    dispatch({
      type: 'navRoot',
      payload: route
    });

    return true;
  };
}
