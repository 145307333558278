import { getCache } from '@flew/cache';
import { dispatch } from '@flew/state';
import { environment } from '../../environments/environment';
import { sessionSet } from '../actions/sessionSet';
import { uiSetDisplay } from '../actions/uiSetDisplay';

const states = ['session', 'uiDisplay'];
const action = {
  session: sessionSet,
  uiDisplay: uiSetDisplay
};

export async function feedStateFromCache() {
  for (const state of states) {
    const cache = await getCache(state);
    if (cache) dispatch(action[state](cache));
    if (!environment.production) {
      console.log(`state loaded for ${state}`, cache);
    }
  }
}
