import {
  PushNotifications,
  PermissionStatus
} from '@capacitor/push-notifications';
import { isPlatform } from './isPlatform';

export function pushRequestPermission(
  callback = (response: PermissionStatus) => {}
) {
  if (isPlatform('capacitor')) {
    PushNotifications.requestPermissions().then(response => callback(response));
  }
}
