import { FCM } from '@capacitor-community/fcm';
import { isPlatform } from './isPlatform';

export function pushRegisterTopic(name: string, callback = () => {}) {
  if (isPlatform('capacitor')) {
    FCM.subscribeTo({ topic: name })
      .then(() => callback())
      .catch(err => console.log(err));
  }
}
