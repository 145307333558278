import { feedStateFromCache } from './feedStateFromCache';
import { Injectable } from '@angular/core';

@Injectable()
export class AppStart {
  async up() {
    await feedStateFromCache();
    return true;
  }
}
