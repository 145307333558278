import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { getState, dispatch } from '@flew/state';
import { navRoot } from '../actions/navRoot';
@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivate, CanActivateChild {
  constructor() {}
  canActivate() {
    return getState('session').user
      ? dispatch(navRoot('/@/home')) && false
      : true;
  }
  canActivateChild() {
    return this.canActivate();
  }
}
