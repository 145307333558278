import { setCache } from '@flew/cache';

export function uiSetDisplay(mode: 'light' | 'dark') {
  return function (dispatch) {
    setCache('uiDisplay', mode);
    dispatch({
      type: 'setDisplay',
      payload: mode
    });
  };
}
