import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { dispatch } from '@flew/state';
import { navBack } from '../../actions/navBack';

@Component({
  selector: 'not-found-page',
  templateUrl: './404-page.component.html',
  styleUrls: ['./404-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  back() {
    dispatch(navBack('/'));
  }
}
