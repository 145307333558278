import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/loggedIn.guard';
import { LoggedOutGuard } from './guards/loggedOut.guard';
import { NotFoundPageComponent } from './pages/404/404-page.component';
import { NotFoundPageModule } from './pages/404/404-page.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shell.module').then(m => m.ShellModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'auth',
    canActivate: [LoggedOutGuard],
    loadChildren: () =>
      import('./pages/auth/auth-page.module').then(m => m.AuthPageModule)
  },
  {
    path: 'chat',
    redirectTo: '/@/home',
    pathMatch: 'full'
  },
  {
    path: 'chat/:type/:id',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./pages/chat/chat-page.module').then(m => m.ChatPageModule)
  },
  {
    path: 'notifications',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./pages/notifications/notifications-page.module').then(
        m => m.NotificationsPageModule
      )
  },
  { path: '**', component: NotFoundPageComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    }),
    NotFoundPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
