import { publish } from '@flew/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { NavOptions } from '../interfaces/navOptions.interface';

export function navBack(
  route,
  options: NavigationOptions = {
    animated: true,
    animationDirection: 'back'
  },
  postNavigation: () => void = () => {}
) {
  return function (dispatch) {
    publish<NavOptions>('navBack', {
      route,
      callback: postNavigation,
      extras: options
    });
    dispatch({
      type: 'navBack'
    });
  };
}
