import { publish } from '@flew/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { NavOptions } from '../interfaces/navOptions.interface';

export function navForward(
  route,
  options: NavigationOptions = {
    animated: true,
    animationDirection: 'forward'
  },
  postNavigation: () => void = () => {}
) {
  return function (dispatch) {
    publish<NavOptions>('navForward', {
      route,
      callback: postNavigation,
      extras: options
    });
    dispatch({
      type: 'navForward'
    });
  };
}
