<ion-content fullscreen>
  <div class="wrap flex flex-col items-center justify-center m-6">
    <img src="/images/404.svg" />
    <div class="text-2xl mt-4 text-gray-400">Page not found</div>

    <ion-button (click)="back()" color="medium" fill="clear">
      <ion-icon name="chevron-back-outline"></ion-icon>
      <span class="w-full"> Go back</span>
      <ion-icon hidden name="chevron-forward-outline"></ion-icon>
    </ion-button>
  </div>
</ion-content>
