import { Component, NgZone, ViewEncapsulation } from '@angular/core';
import { subscribe } from '@flew/core';
import {
  NavController,
  AlertController,
  Platform,
  LoadingController
} from '@ionic/angular';
import { NavOptions } from './interfaces/navOptions.interface';
import { AlertOptions } from './interfaces/alert.interface';
import { uiToggleDark } from './effects/uiToggleDark';
import { dispatch } from '@flew/state';
import { uiSetPlatforms } from './actions/uiSetPlatforms';

import { pushRequestPermission } from './effects/pushRequestPermission';
import { pushRegister } from './effects/pushRegister';
import { pushActionPerformed } from './effects/pushActionPerformed';
import { navForward } from './actions/navForward';
import { pushRegisterTopic } from './effects/pushRegisterTopic';
import { environment } from '../environments/environment';
@Component({
  selector: 'chat-app',
  template: `<ion-app><ion-router-outlet></ion-router-outlet></ion-app>`,
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(
    private zone: NgZone,
    private navController: NavController,
    private alertController: AlertController,
    private platform: Platform,
    private loadingController: LoadingController
  ) {
    this.initPlatform();
    this.initListeners();
  }

  initPlatform() {
    dispatch(uiSetPlatforms(this.platform.platforms()));
    pushRequestPermission(response =>
      response?.receive === 'granted'
        ? pushRegister(() =>
            !environment.production ? pushRegisterTopic('test') : ''
          )
        : ''
    );
  }

  initListeners() {
    this.subscribePushReceived();
    this.subscribeNavRoot();
    this.subscribeNavForward();
    this.subscribeNavBack();
    this.subscribePresentAlert();
    this.subscribePresentLoading();
    this.subscribeHideLoading();
    this.subscribeUiChanges();
  }

  subscribePushReceived() {
    pushActionPerformed(action => {
      const entity = action.notification.data.entityType;
      const from = action.notification.data.fromId;
      const to = action.notification.data.toId;
      const url =
        entity === 'group'
          ? `/chat/${entity}/${to}`
          : `/chat/${entity}/${from}`;
      setTimeout(() => dispatch(navForward(url)), 250);
    });
  }

  subscribeNavRoot() {
    subscribe<NavOptions>(`navRoot`, options =>
      this.zone.run(() =>
        this.navController
          .navigateRoot([options.route], options.extras)
          .then(() => (options.callback ? options.callback() : null))
      )
    );
  }

  subscribeNavForward() {
    subscribe<NavOptions>(`navForward`, options =>
      this.zone.run(() =>
        this.navController
          .navigateForward([options.route], options.extras)
          .then(() => (options.callback ? options.callback() : null))
      )
    );
  }

  subscribeNavBack() {
    subscribe<NavOptions>(`navBack`, options =>
      this.zone.run(() =>
        this.navController
          .navigateBack([options.route], options.extras)
          .then(() => (options.callback ? options.callback() : null))
      )
    );
  }

  subscribePresentAlert() {
    subscribe<AlertOptions>(`presentAlert`, options =>
      this.zone.run(async () => {
        const alert = await this.alertController.create(options);
        alert.present();
      })
    );
  }

  subscribePresentLoading() {
    subscribe<any>(
      `presentLoading`,
      (
        options = {
          cssClass: 'app-loading',
          message: 'Please wait...',
          duration: 20000
        }
      ) =>
        this.zone.run(async () => {
          const loader = await this.loadingController.create(options);
          loader.present();
        })
    );
  }

  subscribeHideLoading() {
    subscribe(`hideLoading`, () =>
      setTimeout(() => this.loadingController.dismiss(), 250)
    );
  }

  subscribeUiChanges() {
    /**
     * Ui Dark Theme
     */
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // toggle based on system preferences
    uiToggleDark(prefersDark.matches);
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener(mediaQuery => uiToggleDark(mediaQuery.matches));
  }
}
