import { createReducer } from '@flew/state';

export const ui = createReducer<{
  display: 'light' | 'dark';
  platforms: string[];
}>(
  {
    display: null,
    platforms: []
  },
  {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setPlatforms: (state, action) => {
      state.platforms = action.payload;
    }
  }
);
