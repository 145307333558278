import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { dispatch, getState } from '@flew/state';
import { uiSetDisplay } from '../actions/uiSetDisplay';
import { isPlatform } from './isPlatform';

export function uiToggleDark(shouldAdd) {
  if (shouldAdd && !getState('ui.display') && getState('session.user')) {
    dispatch(uiSetDisplay('dark'));
  }

  document.body.classList.toggle(
    'dark',
    (shouldAdd && !getState('ui.display')) || getState('ui.display') === 'dark'
  );

  if (isPlatform('capacitor')) {
    if (getState('ui.display') === 'dark') {
      Keyboard.setStyle({
        style: KeyboardStyle.Dark
      });
    } else {
      Keyboard.setStyle({
        style: KeyboardStyle.Light
      });
    }
  }
}
